<template>
  <b-form ref="formDireccion">
    <div class="google-map" ref="googleMap" style="height: 300px"></div>
    <div class="row p-3 mb-5">
      <div class="col-12 container-searcher">
        <label for="inputAutocomplete" class="mr-2"
          >Busca tu dirección aqui:</label
        >
        <input
          ref="inputAutocomplete"
          id="inputAutocomplete"
          name="inputAutocomplete"
          required
          autocomplete="off"
        />
      </div>
      <!-- identificacion-->
      <b-form-group
        label="Nombre de la dirección *"
        label-for="identificacion_direccion"
        class="col-md-4"
      >
        <b-form-input
          id="identificacion_direccion"
          v-model.trim="formDireccion.identificacion"
          type="text"
          ref="identificacion"
          :state="identificacionState"
          required
        ></b-form-input>
      </b-form-group>
      <!-- ciudad_id-->
      <b-form-group
        id="ciudad"
        label="Ciudad *:"
        label-for="ciudad"
        class="col-md-4"
      >
        <b-form-select
          id="ciudad"
          v-model="formDireccion.ciudad_id"
          :options="ciudades"
          ref="ciudadSelect"
          :state="ciudadState"
          required
        ></b-form-select>
      </b-form-group>
      <!-- direccion-->
      <b-form-group label="Dirección *" label-for="direccion" class="col-md-4">
        <b-form-input
          id="direccion"
          v-model.trim="formDireccion.direccion"
          type="text"
          ref="direccion"
          :state="direccionState"
          disabled
          required
        ></b-form-input>
      </b-form-group>
      <!-- torre_manzana-->
      <b-form-group
        label="N° Torre / Manzana"
        label-for="torre_manzana"
        class="col-md-2"
      >
        <b-form-input
          id="torre_manzana"
          v-model.trim="formDireccion.torre_manzana"
          type="text"
        ></b-form-input>
      </b-form-group>
      <!-- apto_casa-->
      <b-form-group
        label="N° Apto / Casa"
        label-for="apto_casa"
        class="col-md-2"
      >
        <b-form-input
          id="apto_casa"
          v-model.trim="formDireccion.apto_casa"
          type="text"
        ></b-form-input>
      </b-form-group>
      <!-- observaciones-->
      <b-form-group
        label="Observaciones"
        label-for="observaciones"
        class="col-md-4"
      >
        <b-form-textarea
          id="observaciones"
          v-model.trim="formDireccion.observaciones"
          placeholder="Explicanos más..."
          rows="2"
          max-rows="3"
        ></b-form-textarea>
      </b-form-group>
      <!-- cx -->
      <!-- cy -->

      <div class="d-flex col-md-4 justify-content-center align-items-center">
        <b-button
          variant="primary"
          @click="handleSubmitDireccion"
          :disabled="inProcess"
          >{{ direccionEnEdicion ? "Guardar Edición" : "Agregar" }}</b-button
        >
        <b-button
          variant="danger"
          class="ml-3"
          :disabled="inProcess"
          v-if="direccionEnEdicion"
          @click="desHacerModoEditDireccion"
          >Cancelar</b-button
        >
      </div>
    </div>
  </b-form>
</template>

<script>
import DireccionesService from "@/services/direcciones.service";
import CiudadesService from "@/services/ciudades.service";
import { mapGetters } from "vuex";
import EventBus from "@/services/eventBus.js";
import GoogleMapsApiLoader from "google-maps-api-loader";
export default {
  name: "CreateDireccion",
  props: { direccionEnEdicion: Object },
  data() {
    return {
      direccionesService: null,
      ciudadesService: null,
      formDireccion: {
        id: null,
        ciudad_id: null,
        direccion: null,
        torre_manzana: "",
        apto_casa: "",
        cx: 0,
        cy: 0,
        observaciones: "",
        identificacion: null,
      },
      inProcess: false,
      ciudades: [{ text: "Seleccione una ciudad", value: null }],
      ciudadState: null,
      direccionState: null,
      identificacionState: null,
      map: null,
      google: null,
      mapConfig: {
        center: {
          lat: 4.683359696392844,
          lng: -74.08041937443866,
        },
        zoom: 11,
        streetViewControl: false,
        restriction: null,
      },
      center: {
        lat: 4.683359696392844,
        lng: -74.08041937443866,
      },
      location: "",
      searchResults: [],
      service: null,
      autocomplete: null,
      direccionAutocomplete: null,
      objectPoligonoCobertura: null,
      objectMarcador: null,
      enCobertura: false,
      poligonoCobertura: [],
      bounds: null,
    };
  },
  created() {
    this.ciudadesService = new CiudadesService();
    this.direccionesService = new DireccionesService();
  },
  async mounted() {
    this.fetchAreaCobertura();
    this.recoveryCiudades();
    const googleMapApi = await GoogleMapsApiLoader({
      libraries: ["places"],
      apiKey: "AIzaSyCFnBjvp7-DXvPyohgfyFPUmRFH4o1CEUM",
    });
    this.google = googleMapApi;
    this.bounds = new this.google.maps.LatLngBounds();
    this.initAutocomplete();
    this.initializeMap();
  },
  methods: {
    fetchAreaCobertura() {
      this.poligonoCobertura = [...this.direccionesService.getAreaCobertura()];
    },
    initAutocomplete() {
      const address1Field = document.querySelector("#inputAutocomplete");

      const cityBounds = new this.google.maps.LatLngBounds(
        new this.google.maps.LatLng(4.4877184702656026, -74.01378714167592),
        new this.google.maps.LatLng(5.043051377397914, -74.25418645127117)
      );

      this.autocomplete = new this.google.maps.places.Autocomplete(
        address1Field,
        {
          bounds: cityBounds,
          strictBounds: false,
          componentRestrictions: { country: ["co"] },
          fields: ["address_components", "geometry"],
          types: ["address"],
        }
      );
      this.autocomplete.addListener("place_changed", () => {
        this.construirDireccion(this.autocomplete.getPlace());
      });
    },
    construirDireccion(direccionObject) {
      let ruta = "";
      let calle = "";
      let barrio = "";
      let localidad = "";
      this.formDireccion.direccion = "";
      this.enCobertura = false;
      if (!direccionObject.geometry) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Cuidado",
          variant: "danger",
          message: "Debes seleccionar una direccion de la lista",
        });
        return false
      }
      this.direccionesService
        .verificarCobertura({
          lat: direccionObject.geometry.location.lat(),
          lng: direccionObject.geometry.location.lng(),
        })
        .then((result) => {
          if (result.data.data) {
            this.enCobertura = true;
            for (const component of direccionObject.address_components) {
              const componentType = component.types[0];
              switch (componentType) {
                case "street_number":
                  calle = component.long_name;
                  break;
                case "route":
                  ruta = component.long_name;
                  break;
                case "neighborhood":
                  barrio = component.long_name;
                  break;
                case "sublocality_level_1":
                  localidad = component.long_name;
                  break;
                default:
                  break;
              }
            }
            this.formDireccion.direccion = `${ruta} ${calle}${
              barrio != "" ? ", " + barrio : ""
            }${localidad != "" ? ", " + localidad : ""}`;
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Felicidades",
              variant: "success",
              message: "Dirección dentro de la zona de cobertura",
            });
          } else {
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Cuidado",
              variant: "danger",
              message:
                "Dirección fuera de la zona de la zona de cobertura, intenta con otra dirección",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.center.lat = direccionObject.geometry.location.lat();
      this.center.lng = direccionObject.geometry.location.lng();
      this.formDireccion.cx = direccionObject.geometry.location.lng();
      this.formDireccion.cy = direccionObject.geometry.location.lat();
      this.changePointer();
    },
    changePointer() {
      this.objectMarcador.setPosition(this.center);
      this.map.setCenter(this.center);
      this.ajustarEnPunto();
    },
    initializeMap() {
      const mapContainer = this.$refs.googleMap;
      const BOGOTA_BOUNDS = {
        north: 5.043051377397914,
        south: 4.4877184702656026,
        west: -75.25418645127117,
        east: -73.01378714167592,
      };
      this.mapConfig.restriction = {
        latLngBounds: BOGOTA_BOUNDS,
        strictBounds: false,
      };
      this.map = new this.google.maps.Map(mapContainer, this.mapConfig);
      this.addMarker();
      this.addZonaDeCobertura();
    },
    addZonaDeCobertura() {
      this.objectPoligonoCobertura = new this.google.maps.Polygon({
        paths: this.poligonoCobertura,
        strokeColor: "#FF0000",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#FF0000",
        fillOpacity: 0.1,
      });
      this.objectPoligonoCobertura.setMap(this.map);
    },
    addMarker() {
      this.objectMarcador = new this.google.maps.Marker({
        position: this.center,
        map: this.map,
        draggable: false,
        title: "Esta es tu dirección seleccionada",
        animation: this.google.maps.Animation.DROP,
      });
    },
    recoveryCiudades() {
      this.ciudadesService
        .getAll()
        .then((result) => {
          if (result.data.status == 200) {
            result.data.data.map((item) => {
              this.ciudades.push({ text: item.nombre, value: item.id });
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    handleSubmitDireccion() {
      if (!this.validationDireccion()) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "warning",
          message: "Debes completar los campos requeridos",
        });
        return false;
      }
      if (!this.enCobertura) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Felicidades",
          variant: "danger",
          message:
            "Dirección fuera de la zona de la zona de cobertura, intenta con otra dirección",
        });
        return false;
      }
      if (this.direccionEnEdicion) {
        this.editarDireccion();
      } else {
        this.crearDireccion();
      }
    },
    desHacerModoEditDireccion() {
      this.$emit("noedit");
    },
    validationDireccion() {
      /* Identificacion */
      const validIdentificacion = this.$refs.identificacion.checkValidity();
      this.identificacionState = validIdentificacion;
      /* direccion */
      const validDireccion = this.$refs.direccion.checkValidity();
      this.direccionState = validDireccion;
      /* ciudad_id */
      const validacionCiudad_id = this.$refs.ciudadSelect.required
        ? this.$refs.ciudadSelect.value
          ? true
          : false
        : true;
      this.ciudadState = validacionCiudad_id;
      return validacionCiudad_id && validDireccion && validIdentificacion;
    },
    crearDireccion() {
      this.direccionesService
        .createDireccion(this.formDireccion)
        .then((result) => {
          if (result.data.status == 200) {
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Felicidades",
              variant: "success",
              message: "Dirección guardada",
            });
            this.$emit("saved", { ...result.data.data });
            this.formDireccion = {
              id: null,
              ciudad_id: null,
              direccion: null,
              torre_manzana: "",
              apto_casa: "",
              cx: 0,
              cy: 0,
              observaciones: "",
              identificacion: null,
            };
          } else {
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Error",
              variant: "warning",
              message: "La dirección no se guardó",
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    editarDireccion() {
      this.direccionesService
        .editDireccion(this.formDireccion)
        .then((result) => {
          if (result.data.status == 200) {
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Felicidades",
              variant: "success",
              message: "Dirección editada",
            });
            this.$emit("edited", { ...result.data.data });
          } else {
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Error",
              variant: "warning",
              message: "La dirección no se guardó",
            });
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    ajustarEnPunto() {
      let bounds = new this.google.maps.LatLngBounds();
      bounds.extend(this.objectMarcador.getPosition());
      this.map.fitBounds(bounds);
      this.map.setZoom(16);
    },
  },
  computed: {
    ...mapGetters(["getUserData"]),
  },
  watch: {
    direccionEnEdicion(newValue) {
      if (newValue) {
        this.enCobertura = true;
        this.formDireccion = { ...newValue };
        this.identificacionState = true;
        this.direccionState = true;
        this.ciudadState = true;
        this.center.lat = this.formDireccion.cy;
        this.center.lng = this.formDireccion.cx;
        this.changePointer();
      } else {
        this.formDireccion = {
          id: null,
          ciudad_id: null,
          direccion: null,
          torre_manzana: "",
          apto_casa: "",
          cx: 0,
          cy: 0,
          observaciones: "",
          identificacion: null,
        };
        this.enCobertura = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-searcher {
  border-radius: 5px;
  border: 1.5px solid #c9719d;
  padding: 15px 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: left;
  margin-bottom: 15px;
}
.container-searcher label {
  font-weight: bold;
  width: 30%;
  text-align: right;
  margin: 0px;
}
#inputAutocomplete {
  width: 50%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (max-width: 600px) {
  .container-searcher label {
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
  }
  #inputAutocomplete {
    width: 100%;
  }
}
</style>
