<template>
  <div class="container-cental">
    <h1 class="mt-5 mb-5">{{ $t("messages.profile") }}</h1>
    <b-tabs content-class="mt-3">
      <b-tab :title="$t('messages.information')" active>
        <b-form ref="formEditUser">
          <div class="row p-3 mb-5">
            <!-- identificacion -->
            <!-- <b-form-group
              :label="$t('messages.identification') + '*'"
              label-for="identificacion"
              class="col-md-6 col-lg-4"
            >
              <b-form-input
                id="identificacion"
                v-model.trim="form.identificacion"
                type="text"
                ref="identificacion"
                :state="identificacionState"
                required
                :disabled="!modeEdit"
              ></b-form-input>
            </b-form-group> -->
            <!-- nombres -->
            <b-form-group :label="$t('messages.firstnames') + '*'" label-for="nombres" class="col-md-6 col-lg-4">
              <b-form-input id="nombres" v-model.trim="form.nombres" type="text" ref="nombres" :state="nombresState"
                required :disabled="!modeEdit"></b-form-input>
            </b-form-group>
            <!-- apellidos -->
            <b-form-group :label="$t('messages.lastnames') + '*'" label-for="apellidos" class="col-md-6 col-lg-4">
              <b-form-input id="apellidos" v-model.trim="form.apellidos" type="text" ref="apellidos"
                :state="apellidosState" required :disabled="!modeEdit"></b-form-input>
            </b-form-group>
            <!-- email -->
            <b-form-group :label="$t('messages.email') + '*'" label-for="email" class="col-md-6 col-lg-4">
              <b-form-input v-model.trim="email" disabled></b-form-input>
            </b-form-group>
            <!-- telefono -->
            <b-form-group :label="$t('messages.phone') + '*'" label-for="telefono" class="col-md-6 col-lg-4">
              <b-form-input placeholder="+(XX)XXX-XXXXXXX" id="telefono" v-model.trim="form.telefono" type="text"
                ref="telefono" :disabled="!modeEdit"></b-form-input>
            </b-form-group>
            <!-- nacimiento	 -->
            <b-form-group :label="$t('messages.birthdate') + '*'" label-for="nacimiento" class="col-md-6 col-lg-4">
              <b-form-input id="nacimiento" v-model.trim="form.nacimiento" type="date" ref="nacimiento"
                :state="nacimientoState" :max="maxDateBirthday" required :disabled="!modeEdit"
                locale="en-EN"></b-form-input>
            </b-form-group>
            <!-- newsletter	 -->
            <b-form-group class="col-sm-12 mt-5" style="display: flex; justify-content: left">
              <b-form-checkbox id="newsletter" name="newsletter" v-model="newsletter" ref="newsletter"
                :disabled="!modeEdit">{{ $t("messages.IWantNewsletter") }}</b-form-checkbox>
            </b-form-group>
          </div>
          <b-button variant="primary" @click="modeEdit = true" v-if="!modeEdit" class="mb-3">{{ $t("messages.edit")
          }}</b-button>
          <b-button variant="warning" @click="handleDeletePerfil" v-if="!modeEdit" class="mb-3 ml-2">{{
            $t("messages.deleteProfile") }}</b-button>
          <b-button variant="secondary" class="mb-3" :disabled="inProcess" @click="desHacerModoEdit" v-if="modeEdit">{{
            $t("messages.cancel") }}</b-button>
          <b-button variant="primary" @click="handleSubmit" :disabled="inProcess" v-if="modeEdit" class="ml-3 mb-3">{{
            $t("messages.accept") }}</b-button>
        </b-form>
      </b-tab>
      <!-- <b-tab title="Direcciones">
        <create-direccion
          :direccionEnEdicion="direccionEnEdicion"
          @noedit="editDireccion(null)"
          @saved="direcciones.push($event)"
          @edited="changeDireccionEdited($event)"
        />
        <b-table
          striped
          hover
          :items="direcciones"
          :fields="fields"
          :stacked="windowWidth < 900"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
        >
          <template #cell(actions)="data">
            <b-icon
              icon="pencil-square"
              variant="warning"
              @click="editDireccion({ ...data.item })"
              scale="1.5"
              style="margin: 0px 10px; cursor: pointer"
              v-b-tooltip.hover
              title="Editar"
            ></b-icon>
            <b-icon
              icon="x-circle"
              variant="danger"
              @click="deleteDireccion({ ...data.item })"
              scale="1.5"
              style="margin: 0px 10px; cursor: pointer"
              v-b-tooltip.hover
              title="Eliminar"
            ></b-icon>
          </template>
        </b-table>
      </b-tab>
      <b-tab title="Mis Pédidos">
        <mis-pedidos/>
      </b-tab> -->
      <b-tab :title="$t('messages.changePassword')">
        <b-form ref="formEditPassword">
          <div class="row p-3 mb-5">
            <!-- password actual -->
            <b-form-group :label="$t('messages.actualPassword') + '*'" label-for="actualPassword"
              class="col-md-6 col-lg-4">
              <b-form-input id="actualPassword" v-model.trim="formPassword.actualPassword" type="password"
                ref="actualPassword" :state="actualPasswordState" required></b-form-input>
            </b-form-group>
            <!-- nuevo password -->
            <b-form-group :label="$t('messages.newPassword') + '*'" label-for="nuevoPassword" class="col-md-6 col-lg-4">
              <b-form-input id="nuevoPassword" v-model.trim="formPassword.nuevoPassword" type="password"
                ref="nuevoPassword" :state="nuevoPasswordState" required></b-form-input>
            </b-form-group>
            <!-- re nuevo password -->
            <b-form-group :label="$t('messages.repeatNewPassword') + '*'" label-for="reNuevoPassword"
              class="col-md-6 col-lg-4">
              <b-form-input id="reNuevoPassword" v-model.trim="formPassword.reNuevoPassword" type="password"
                ref="reNuevoPassword" :state="reNuevoPasswordState" required></b-form-input>
            </b-form-group>
          </div>
          <b-button variant="primary" @click="handleChangePassword" :disabled="inProcess">{{ $t("messages.changePassword")
          }}</b-button>
        </b-form>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import UsersService from "@/services/users.service";
import NewsletterService from "@/services/newsletter.service";
import DireccionesService from "@/services/direcciones.service";
import EventBus from "@/services/eventBus.js";
import { mapGetters, mapMutations } from "vuex";
import CreateDireccion from "@/components/PublicComponents/CreateDireccion.vue";
import MisPedidos from "@/components/PublicComponents/MisPedidos.vue";
import { Helpers } from "@/mixins/Helpers";
export default {
  components: {
    "create-direccion": CreateDireccion,
    "mis-pedidos": MisPedidos,
  },
  name: "UserProcessPublic",
  mixins: [Helpers],
  data() {
    return {
      newsletterService: null,
      direccionesService: null,
      usersService: null,
      form: {
        id: null,
        identificacion: null,
        nombres: null,
        apellidos: null,
        telefono: null,
        nacimiento: null
      },
      newsletter: false,
      formPassword: {
        id: null,
        actualPassword: null,
        nuevoPassword: null,
        reNuevoPassword: null,
      },
      identificacionState: null,
      nombresState: null,
      apellidosState: null,
      nacimientoState: null,
      actualPasswordState: null,
      nuevoPasswordState: null,
      reNuevoPasswordState: null,
      modeEdit: false,
      maxDateBirthday: this.$moment().format("YYYY-MM-DD"),
      inProcess: false,
      email: "",
      direcciones: [],
      sortBy: "direccion",
      fields: [
        { key: "identificacion", sortable: true },
        { key: "nombre_ciudad", sortable: true },
        { key: "direccion", sortable: true },
        { key: "torre_manzana", sortable: true },
        { key: "apto_casa", sortable: true },
        { key: "observaciones", sortable: true },
        { key: "actions", sortable: false },
      ],
      itemSeleted: null,
      from: "direccionesprofile",
      windowWidth: window.innerWidth,
      sortDesc: false,
      direccionEnEdicion: null,
    };
  },
  created() {
    this.usersService = new UsersService();
    this.newsletterService = new NewsletterService();
    this.direccionesService = new DireccionesService();
  },
  async mounted() {
    /* this.recoveryDirecciones(); */
    this.$nextTick(() => {
      window.addEventListener("resize", () => {
        this.windowWidth = window.innerWidth;
      });
    });

    this.form = {
      id: this.getUserData.id,
      identificacion: this.getUserData.identificacion,
      nombres: this.allFirstLettersUpperCase(this.getUserData.nombres),
      apellidos: this.allFirstLettersUpperCase(this.getUserData.apellidos),
      telefono: this.getUserData.telefono ?? "",
      nacimiento: this.getUserData.nacimiento,
    };

    if (this.form.telefono && this.form.telefono.length > 0 && this.form.telefono[0] != "+") {
      this.form.telefono = "+" + this.form.telefono;
    }

    this.email = this.getUserData.email.toLowerCase();
    this.newsletter = this.getUserData.newsletter;
    this.formPassword.id = this.getUserData.id;
    EventBus.$on("RESPONSE_MODAL_QUESTION", (payLoad) => {
      if (payLoad.from == this.from) {
        switch (payLoad.action) {
          case "delete":
            this.ejecuteDelete();
            break;
          case "delete_perfil":
            this.deletePerfil();
            break;
          default:
            break;
        }
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    ...mapMutations(["updateDataUser", "logout"]),
    handleDeletePerfil() {
      EventBus.$emit("SHOW_MODAL_QUESTION", {
        titleModal: this.$t('messages.elimination'),
        messageModal: this.$t("messages.deletePerfilQuestion"),
        action: "delete_perfil",
        from: this.from,
      });
    },
    deletePerfil() {
      this.usersService.deletePerfil().then((result) => {
        if (result.data.status == 200) {
          this.logout();
          this.$router.push({ name: "home" });
        }
      });
    },
    deleteDireccion(item) {
      this.itemSeleted = { ...item };
      EventBus.$emit("SHOW_MODAL_QUESTION", {
        titleModal: this.$t('messages.elimination'),
        messageModal: "¿Seguro desea eliminar la dirección seleccionada?",
        action: "delete",
        from: this.from,
      });
    },
    ejecuteDelete() {
      if (!this.itemSeleted) return false;
      this.direccionesService
        .deleteDireccion(this.itemSeleted.id)
        .then((result) => {
          if (result.data.status == 200) {
            this.direcciones = this.direcciones.filter((item) => {
              return item.id != this.itemSeleted.id;
            });
            this.itemSeleted = null;
            this.direccionEnEdicion = null;
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "",
              variant: "success",
              message: "Dirección eliminada",
            });
          }
        })
        .catch(() => {
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: "Error al eliminar registro",
          });
        });
    },
    recoveryDirecciones() {
      this.direccionesService
        .getDireccionesForIdUser()
        .then((result) => {
          this.direcciones = [];
          if (result.data.status == 200) {
            this.direcciones = [...result.data.data];
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    handleChangePassword() {
      if (!this.validationPassword()) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "warning",
          message: this.$t("messages.completeTheFields"),
        });
        return false;
      }
      this.editPassword();
    },
    editPassword() {
      this.inProcess = true;
      this.usersService
        .changePassword(this.formPassword)
        .then((result) => {
          if (result.data.status == 200) {
            this.formPassword.actualPassword = null;
            this.formPassword.nuevoPassword = null;
            this.formPassword.reNuevoPassword = null;
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "",
              variant: "success",
              message: this.$t("messages.passwordChanged"),
            });
          } else {
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Error",
              variant: "danger",
              message: this.translateErrors(result.data.errores).join(" - "),
            });
          }
          this.inProcess = false;
        })
        .catch(() => {
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: this.$t("messages.saveError"),
          });
          this.inProcess = false;
        });
    },
    validationPassword() {
      /* Password */
      const validActualPassword = this.$refs.actualPassword.checkValidity();
      this.actualPasswordState = validActualPassword;
      /* Nuevo Password */
      const validNuevoPassword = this.$refs.nuevoPassword.checkValidity();
      this.nuevoPasswordState = validNuevoPassword;
      /*  Re Nuevo Password */
      const validReNuevoPassword = this.$refs.reNuevoPassword.checkValidity();
      this.reNuevoPasswordState = validReNuevoPassword;
      if (
        this.formPassword.reNuevoPassword != this.formPassword.nuevoPassword
      ) {
        this.reNuevoPasswordState = false;
        this.nuevoPasswordState = false;
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "danger",
          message: this.$t("messages.passwordsNotMatch"),
        });
        return false;
      }
      return validNuevoPassword && validReNuevoPassword && validActualPassword;
    },
    handleSubmit() {
      if (!this.validation()) {
        EventBus.$emit("MOSTRAR_TOAST", {
          title: "Error",
          variant: "warning",
          message: this.$t("messages.completeTheFields"),
        });
        return false;
      }
      this.editRegister();
    },
    editRegister() {
      this.inProcess = true;

      if (this.newsletter) {
        try {
          this.newsletterService.suscribe({
            email: this.email,
            idioma: this.$i18n.locale === "es" ? "spanish" : "english",
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          this.newsletterService.unsuscribeByEmail(this.email);
        } catch (error) {
          console.log(error);
        }
      }

      if (this.form.telefono && this.form.telefono.toString().length > 0 && this.form.telefono.toString()[0] != "+") {
        this.form.telefono = "+" + this.form.telefono.toString();
      }

      this.usersService
        .editUserPublic(this.form)
        .then((result) => {
          if (result.data.status == 200) {
            this.modeEdit = false;

            if (result.data.data.telefono && result.data.data.telefono.toString().length > 0 && result.data.data.telefono.toString()[0] != "+") {
              result.data.data.telefono = "+" + result.data.data.telefono.toString();
            }

            this.form.identificacion = result.data.data.identificacion;
            this.form.nombres = this.allFirstLettersUpperCase(
              result.data.data.nombres
            );
            this.form.apellidos = this.allFirstLettersUpperCase(
              result.data.data.apellidos
            );
            this.form.telefono = result.data.data.telefono;

            this.form.nacimiento = result.data.data.nacimiento;

            this.updateDataUser({ ...result.data.data, newsletter: this.newsletter });
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "",
              variant: "success",
              message: this.$t("messages.editSuccess"),
            });
          } else {
            EventBus.$emit("MOSTRAR_TOAST", {
              title: "Error",
              variant: "danger",
              message: this.translateErrors(result.data.errores).join(" - "),
            });
          }
          this.inProcess = false;
        })
        .catch(() => {
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: this.$t("messages.saveError"),
          });
          this.inProcess = false;
        });
    },
    validation() {
      /* identificacion*/
      /* const validIdentificacion = this.$refs.identificacion.checkValidity();
      this.identificacionState = validIdentificacion; */
      /* nombres*/
      const validNombres = this.$refs.nombres.checkValidity();
      this.nombresState = validNombres;
      /* apellidos*/
      const validApellidos = this.$refs.apellidos.checkValidity();
      this.apellidosState = validApellidos;
      /* telefono*/
      /* const validTelefono = this.$refs.telefono.checkValidity();
      this.telefonoState = validTelefono; */
      /* nacimiento*/
      const validNacimiento = this.$refs.nacimiento.checkValidity();
      this.nacimientoState = validNacimiento;
      return validNombres && validApellidos && validNacimiento;
    },
    desHacerModoEdit() {
      this.modeEdit = false;
      this.form = {
        id: this.getUserData.id,
        identificacion: this.getUserData.identificacion,
        nombres: this.allFirstLettersUpperCase(this.getUserData.nombres),
        apellidos: this.allFirstLettersUpperCase(this.getUserData.apellidos),
        telefono: this.getUserData.telefono,
        nacimiento: this.getUserData.nacimiento,
      };
      this.newsletter = this.getUserData.newsletter;
    },
    editDireccion(item) {
      if (item) {
        this.direccionEnEdicion = { ...item };
      } else {
        this.direccionEnEdicion = null;
      }
    },
    changeDireccionEdited(event) {
      this.direcciones = this.direcciones.filter((item) => {
        return item.id != event.id;
      });
      this.direcciones.push({ ...event });
      this.direccionEnEdicion = null;
    },
  },
  computed: {
    ...mapGetters(["getUserData"]),
  },
};
</script>

<style lang="scss" scoped>
.container-cental {
  width: 100%;
  min-height: 700px;
  padding: 0px 300px;
}

@media (max-width: 1400px) {
  .container-cental {
    padding: 0px 10px;
  }
}
</style>
