<template>
  <div :class="{ 'container-cental': $route.name === 'MisPedidos' }">
    <h1 class="mt-5 mb-5" v-if="$route.name === 'MisPedidos'">MIS PEDIDOS</h1>
    <div class="container-table" v-if="pedidos.length > 0">
      <b-table
        striped
        :stacked="windowWidth < 600"
        hover
        :items="pedidos"
        :fields="fields"
        :busy="loadingData"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        sort-icon-left
        responsive="sm"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template #cell(fecha_programada_entrega)="data">
          <span
            >{{ data.item.fecha | fecha }} de
            {{ secondsToHM(data.item.hora_inicio) }} a
            {{ secondsToHM(data.item.hora_fin) }}</span
          >
        </template>
        <template #cell(pagado)="data">
          <span>{{
            data.item.pago ? data.item.pago.status_del_pago : "NO"
          }}</span>
        </template>
        <template #cell(solicitado)="data">
          <span>{{ data.item.created }}</span>
        </template>
        <template #cell(estado)="data">
          <span>{{ estadoDelPedido(data.item.estado_del_pedido).text }}</span>
        </template>
        <template #cell(total)="data">
          <span>${{ total(data.item.productos) | numero }}</span>
        </template>
        <template #cell(actions)="data">
          <b-button
            size="sm"
            @click="data.toggleDetails"
            :class="{ 'mr-2': true, 'mb-3': windowWidth < 600 }"
          >
            {{ data.detailsShowing ? "Ocultar" : "Mostrar" }} Detalles
          </b-button>
          <form
            :action="wompiCheckout"
            method="GET"
            v-if="
              data.item.pago
                ? data.item.pago.status_del_pago.toUpperCase() != 'APPROVED' &&
                  data.item.pago.status_del_pago.toUpperCase() != 'PENDING'
                : true
            "
          >
            <!-- OBLIGATORIOS -->
            <input type="hidden" name="public-key" :value="publicKeyWompi" />
            <input type="hidden" name="currency" value="COP" />
            <input
              type="hidden"
              name="amount-in-cents"
              :value="total(data.item.productos) * 100"
            />
            <input
              type="hidden"
              name="reference"
              :value="data.item.codigo_pedido"
            />
            <!-- OPCIONALES -->
            <input type="hidden" name="redirect-url" value="" />
            <!-- <input type="hidden" name="tax-in-cents:vat" value="IVA_EN_CENTAVOS" />
            <input type="hidden" name="tax-in-cents:consumption" value="IMPOCONSUMO_EN_CENTAVOS" /> -->
            <b-button size="sm" class="mr-2" type="submit">
              Pagar Pedido
            </b-button>
          </form>
        </template>
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong style="margin-left: 5px">Cargando Datos...</strong>
          </div>
        </template>
        <template #row-details="data">
          <b-card>
            <b-row
              class="mb-2 row-container"
              v-for="(producto, index) in data.item.productos"
              :key="index"
            >
              <span class="ml-2" style="font-size: 1.3rem">{{
                producto.cantidad
              }}</span>
              <span class="ml-3">{{
                producto.producto | todasLasPrimerasMayusculas
              }}</span>
              <span class="ml-2 mr-3" style="font-size: 0.8rem"
                >( {{ producto.presentacion }} )</span
              >
              <span class="ml-2">{{
                (producto.cantidad * producto.precio_unitario) | numero
              }}</span>
              <span v-if="producto.es_adicion == 1" class="ml-3"
                >(adición)</span
              >
            </b-row>
            <b-button size="sm" @click="data.toggleDetails">Ocultar</b-button>
          </b-card>
        </template>
      </b-table>
    </div>
    <div class="container-paginator" v-if="pedidos.length > 0">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>
      <span style="margin-left: 15px">Items por página</span>
      <b-form-select
        v-model="perPage"
        :options="optionsPerPage"
        style="width: 80px; margin-left: 10px"
      ></b-form-select>
    </div>
    <h2 v-if="pedidos.length == 0">No tiene pedidos</h2>
  </div>
</template>

<script>
import PedidosService from "@/services/pedidos.service";
import EventBus from "@/services/eventBus.js";
import { Helpers } from "@/mixins/Helpers";
import { mapGetters } from "vuex";
export default {
  name: "MisPedidos",
  mixins: [Helpers],
  data() {
    return {
      pedidosService: null,
      sortBy: "solicitado",
      fields: [
        { key: "codigo_pedido", sortable: true },
        { key: "fecha_programada_entrega", sortable: true },
        { key: "total", sortable: true },
        { key: "pagado", sortable: true },
        { key: "estado", sortable: true },
        { key: "solicitado", sortable: true },
        { key: "actions", sortable: false },
      ],
      pedidos: [],
      windowWidth: window.innerWidth,
      loadingData: false,
      perPage: 10,
      currentPage: 1,
      optionsPerPage: [5, 10, 25, 50, 100],
      itemSeleted: null,
      sortDesc: false,
      wompiCheckout: process.env.VUE_APP_API_WOMPI_CHECKOUT,
      publicKeyWompi: process.env.VUE_APP_API_WOMPI_PUBLIC_KEY,
    };
  },
  created() {
    this.pedidosService = new PedidosService();
  },
  mounted() {
    this.getItemsForList();
    this.$nextTick(() => {
      window.addEventListener("resize", () => {
        this.windowWidth = window.innerWidth;
      });
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    getItemsForList() {
      this.loadingData = true;
      this.pedidosService
        .pedidosDeUnUsuario()
        .then((result) => {
          this.pedidos = result.data.data.map((item) => {
            item.isActive = false;
            return item;
          });
          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
          EventBus.$emit("MOSTRAR_TOAST", {
            title: "Error",
            variant: "danger",
            message: "Error al recuperar items de la lista",
          });
        });
    },
    total(productos) {
      let suma = 0;
      console.log("productos", productos);
      productos.forEach((element) => {
        suma += element.cantidad * element.precio_unitario;
      });
      return suma;
    },
  },
  computed: {
    ...mapGetters(["getToken"]),
    rows() {
      return this.pedidos.length;
    },
  },
  watch: {
    getToken() {
      this.getItemsForList();
    },
  },
};
</script>

<style lang="scss" scoped>
.container-cental {
  width: 100%;
  min-height: 700px;
  padding: 0px 300px;
}

.container-table {
  width: 100%;
  min-height: 300px;
  padding-bottom: 20px;
}
.container-paginator {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  width: 100%;
  padding-bottom: 20px;
}
.container-actions-top-table {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  padding: 20px 30px;
}
.row-container {
  display: flex;
  align-items: center;
}
@media (max-width: 1400px) {
  .container-cental {
    padding: 0px 10px;
  }
}
@media (max-width: 470px) {
  .container-paginator {
    flex-direction: column;
    align-items: center;
  }
}
</style>
